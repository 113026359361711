import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
Chart.register(
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
  Tooltip,
  Filler,
)

Object.assign(Chart.defaults.scales.time.time, {
  unit: 'hour',
  displayFormats: {
    datetime: 'PPpp', //'MMM d, yyyy, h:mm:ss aaaa',
    millisecond: 'h:mm:ss.SSS aaaa',
    second: 'pp', // 'h:mm:ss aaaa',
    minute: 'p', //'h:mm aaaa',
    hour: 'p', // 'ha',
    day: 'PP', // 'MMM d',
    week: 'PP',
    month: 'PP', //'MMM yyyy',
    quarter: 'qqq - yyyy',
    year: 'yyyy',
  },
})
