import React from 'react'

interface ChartFigureProps {
  children?: React.ReactNode
  caption?: string
}
export const ChartFigure: React.FC<ChartFigureProps> = ({
  children,
  caption,
}) => (
  <figure
    style={{
      position: 'relative',
      margin: 0,
      paddingLeft: '1rem',
      minHeight: '50vh',
    }}
  >
    {children}
    {caption && (
      <figcaption
        style={{
          color: 'white',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <span style={{ transform: 'translateX(-2rem) rotate(-90deg)' }}>
          {caption}
        </span>
      </figcaption>
    )}
  </figure>
)
