import React, { useRef, useLayoutEffect, useEffect, useState } from 'react'
import { Chart, ChartDataset, ScatterDataPoint } from 'chart.js'
import { RawTea, Tea } from './types'
import { years } from './config'
import { colors } from './colors'
import { normalizeTea } from './util/normalizeLabel'
import { ChartFigure } from './ChartFigure'

interface AoeChartProps {}

type Dataset = ChartDataset<'line', ScatterDataPoint[]>

function parseRawTeaTotals(data: RawTea[]) {
  const teas: Record<string, any> = {}
  function addTea(tea: Tea) {
    if (!teas[tea.label]) {
      teas[tea.label] = 0
    }

    teas[tea.label] += tea.liters
  }
  for (const tea of data) {
    addTea(normalizeTea(tea))
  }
  return teas
}

export const useTeaTotals = () => {
  const [data, setData] = useState<Dataset[]>([])
  useEffect(() => {
    Promise.all(
      years.map(year =>
        fetch(`/data/${year}.json`).then(async res => ({
          year,
          data: (await res.json()) as RawTea[],
        })),
      ),
    ).then(allData => {
      const datasets: Record<string, Dataset> = {}
      for (let year of allData) {
        const parsed = parseRawTeaTotals(year.data)
        const total = Object.values(parsed).reduce((t, v) => (t += v), 0)
        Object.keys(parsed).forEach(key => {
          if (!datasets[key]) {
            const { backgroundColor } = colors[key] || ({} as any)
            datasets[key] = {
              label: key,
              data: [],
              fill: true,
              backgroundColor,
              pointHitRadius: 75,
              pointRadius: 0,
              borderColor: 'transparent',
              tension: 0.5,
              cubicInterpolationMode: 'default',
            }
          }
          datasets[key].data.push({
            x: +year.year,
            y: (parsed[key] / total) * 100,
          })
        })
      }
      setData(
        Object.values(datasets).sort((a, b) => b.data[0]!.y - a.data[0]!.y),
      )
    })
  }, [])
  return data
}

export const AoeChart: React.FC<AoeChartProps> = () => {
  const datasets = useTeaTotals()
  const ref = useRef<HTMLCanvasElement>(null)
  const chart = useRef<Chart<'line'> | null>(null)
  console.log(datasets)

  useLayoutEffect(() => {
    if (ref.current) {
      chart.current = new Chart(ref.current, {
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              mode: 'index',
              position: 'nearest',
              callbacks: {
                label: item =>
                  `${item.dataset.label}: ${Number(item.formattedValue).toFixed(
                    0,
                  )}%`,
              },
            },
            legend: { labels: { color: 'white' } },
          },
          scales: {
            x: {
              type: 'category',
              ticks: { color: 'rgba(255,255,255,0.9)' },
              grid: { color: 'rgba(255,255,255,0.1)' },
            },
            y: {
              type: 'linear',
              stacked: true,
              min: 0,
              title: { text: 'test' },
              ticks: { color: 'rgba(255,255,255,0.9)' },
              grid: { color: 'rgba(255,255,255,0.1)' },
            },
          },
        },
        data: {
          labels: years,
          datasets,
        },
      })
    }
    return () => {
      if (chart.current) chart.current.destroy()
      chart.current = null
    }
  }, [ref.current]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chart.current && datasets) {
      chart.current.data.datasets = datasets
      chart.current.update()
    }
  }, [datasets])

  return (
    <div>
      <ChartFigure>
        <canvas ref={ref} />
      </ChartFigure>
    </div>
  )
}
