import { mean } from 'lodash'
import { ChartDataset } from 'chart.js'

import { colors } from './colors'
import { RawTea, Tea } from './types'
import { regular } from './config'
import { normalizeTea } from './util/normalizeLabel'

export interface DensityDatapoint {
  x: Date
  y: number
}
export interface DensityDataset
  extends ChartDataset<'line', DensityDatapoint[]> {
  label: string
  total: number
}

export function parseRawTea(data: RawTea[]) {
  const datasets: Record<string, DensityDataset> = {}
  function addTea(tea: Tea) {
    tea = normalizeTea(tea)
    if (!datasets[tea.label]) {
      const { backgroundColor } = colors[tea.label] || ({} as any)
      datasets[tea.label] = {
        label: tea.label,
        total: 0,
        fill: true,
        data: [],
        backgroundColor,
        pointRadius: 0,
        tension: 0.5,
        cubicInterpolationMode: 'default',
        borderColor: 'transparent',
      }
    }

    datasets[tea.label].total! += tea.liters
    datasets[tea.label].data.push({
      x: tea.time,
      y: tea.liters,
    })
  }

  for (const origTea of data) {
    const tea: Tea = normalizeTea(origTea)
    addTea(tea)
  }
  return datasets
}
export function convertData(data: RawTea[], ticks: number) {
  const datasets = parseRawTea(data)

  const xTicks: number[] = []

  const start = data[0]
  const end = data[data.length - 1]
  const diff = new Date(end.time).getTime() - new Date(start.time).getTime()
  let startDate = new Date(start.time).getTime()
  for (let i = 0; i < ticks; ++i) {
    xTicks.push(i * (diff / ticks))
  }

  Object.values(datasets).forEach(dataset => {
    // using diff / ticks here since I needed some absurdely large number for it to work and give any reasonable data
    var kde = kernelDensityEstimator(kernelEpanechnikov(diff / ticks), xTicks)
    var density = kde(
      data.map(function (d) {
        return {
          date: new Date(d.time).getTime() - startDate,
          count:
            dataset.label === 'Populi'
              ? !regular.includes(d.name)
              : dataset.label === d.name,
        }
      }),
    )
    dataset.data = density.map(({ x, y }, i) => ({
      x: new Date(x + startDate),
      // This magic number is pulled from thin air
      //y: y * dataset.data[i].y,
      y: (dataset.label === 'Populi' ? y * 1.5 : y * 3) * 1e6 * 3600,
    }))
  })
  return Object.values(datasets).sort((a, b) => a.total! - b.total!)
}
// Function to compute density
function kernelDensityEstimator(kernel: (v: number) => number, X: number[]) {
  return function (V: { date: number; count: boolean }[]) {
    return X.map(function (x) {
      return {
        x,
        y: mean(V.map(({ count, date }) => (count ? kernel(x - date) : 0))),
      }
    })
  }
}

function kernelEpanechnikov(k: number) {
  return function (v: number) {
    return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0
  }
}
