import { regular } from '../config'
import { Tea, RawTea } from '../types'

export function normalizeTea(tea: RawTea | Tea): Tea {
  if (tea.name.startsWith('Lapsang')) {
    tea.name = 'Lapsang'
  } else if (tea.name.startsWith('Rooibos')) {
    tea.name = 'Rooibos'
  }

  return {
    ...tea,
    time: new Date(tea.time),
    label: regular.includes(tea.name) ? tea.name : 'Populi',
  }
}
