import React, { useEffect, useState } from 'react'
import './App.css'
import { ChartComponent } from './Chart'
import { years } from './config'
import { AoeChart } from './AoeChart'
import './chart-config'

const App: React.FC = () => {
  const [data, setData] = useState<any | null>(null)
  const initialYear =
    document.location.hash.replace('#', '') || years[years.length - 1]
  const [year, setYear] = useState(initialYear)
  const [ticks, setTicks] = useState(60)
  useEffect(() => {
    const listener = () => {
      const y = document.location.hash.replace('#', '')
      if (y !== year) setYear(y)
    }
    window.addEventListener('hashchange', listener)
    return () => window.removeEventListener('hashchange', listener)
  }, [year])

  useEffect(() => {
    fetch(`/data/${year}.json`)
      .then(res => res.json())
      .then(res => setData(res))
      .catch(() => {
        console.error('Invalid year', year)
        window.location.hash = years[years.length - 1]
      })
  }, [year])
  return (
    <div className="App">
      <h1>LinCon testatistik</h1>
      <select
        value={year}
        onChange={newYear => (window.location.hash = newYear.target.value)}
      >
        {years.map(y => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
      {process.env.NODE_ENV === 'development' && (
        <div style={{ margin: '1rem' }}>
          <input
            type="range"
            min={0}
            max={300}
            value={ticks}
            onChange={e => setTicks(+e.target.value)}
            style={{ verticalAlign: 'middle' }}
          />
          {ticks} ticks
        </div>
      )}
      <section>
        <h2>Hur mycket te bryggs per timme på LinCon?</h2>
        {data && <ChartComponent data={data} ticks={ticks} />}
      </section>
      <section>
        <h2>Vilket te är mest populärt från år till år?</h2>
        <AoeChart />
      </section>
      {/*
      <Chart2 />
      <Chart3 />
      <Chart4 />
      */}
    </div>
  )
}

export default App
