export const colors: Record<string, any> = {
  Konventste: {
    backgroundColor: '#6fb0e2',
  },
  'Sencha Lime': {
    backgroundColor: '#b8dc89',
  },
  Lapsang: { backgroundColor: '#77828b' },
  'Golden Nepal': { backgroundColor: '#e7ecff' },
  Rooibos: {
    backgroundColor: 'hsla(357, 70%, 68%, 1)',
  },
  Populi: {
    backgroundColor: '#ffb6c1',
  },
}
