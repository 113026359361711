import React, { useRef, useLayoutEffect, useEffect } from 'react'
import { Chart } from 'chart.js'
import 'chartjs-adapter-date-fns'

import { convertData, DensityDatapoint } from './createDensityData'
import { RawTea } from './types'
import { ChartFigure } from './ChartFigure'
import sv from 'date-fns/locale/sv'
import { format } from 'date-fns'

interface ChartProps {
  data: RawTea[]
  ticks: number
}

export const ChartComponent: React.FC<ChartProps> = ({ data, ticks }) => {
  const ref = useRef<HTMLCanvasElement>(null)
  const chart = useRef<Chart<'line', DensityDatapoint[]> | null>(null)

  useLayoutEffect(() => {
    if (ref.current) {
      chart.current = new Chart(ref.current, {
        type: 'line',
        options: {
          elements: { line: {} },

          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { labels: { color: 'white' } },
            tooltip: {
              mode: 'index',
              intersect: false,
              position: 'average',
              itemSort: (a, b) => b.datasetIndex! - a.datasetIndex!,
              callbacks: {
                title: items =>
                  format((items[0].raw as DensityDatapoint).x, 'PPPP p', {
                    locale: sv,
                  }),
                label: item =>
                  `${item.dataset.label}: ${Number(item.formattedValue).toFixed(
                    1,
                  )} liter/timme`,
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              ticks: {
                color: 'rgba(255,255,255,0.9)',
                source: 'auto',
                maxRotation: 0,
              },
              grid: { color: 'rgba(255,255,255,0.1)' },
              adapters: { date: { locale: sv } },
            },
            yAxes: {
              type: 'linear',
              stacked: true,
              ticks: { color: 'rgba(255,255,255,0.9)' },
              grid: { color: 'rgba(255,255,255,0.1)' },
            },
          },
        },
        data: {
          datasets: convertData(data, ticks),
        },
      })
    }
    return () => {
      if (chart.current) chart.current && chart.current.destroy()
      chart.current = null
    }
  }, [ref.current]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chart.current) {
      console.log(chart.current.data.datasets)
      const datasets = convertData(data, ticks)
      if (chart.current.data.datasets) {
        chart.current.data.datasets.forEach((set, i) => {
          Object.assign(
            set,
            datasets.find(d => d.label === set.label),
          )
        })
        chart.current.data.datasets.sort((a: any, b: any) => a.total - b.total)
      } else {
        chart.current.data.datasets = datasets
      }

      chart.current.update()
    }
  }, [data, ticks])

  return (
    <div>
      <ChartFigure caption="liter / timme">
        <canvas ref={ref} />
      </ChartFigure>
    </div>
  )
}
